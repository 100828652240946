import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "1rem 2rem",
    marginTop: "5rem",
    fontSize: "18px",
    fontFamily: "sans-serif",
  },
  flex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
    alignItems: "baseline",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  addressSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexGrow: 1,
    marginTop: "3rem",
  },
  invoiceDet: {
    width: "20%",
    textAlign: "right",
  },
  invoiceText: {
    width: "50%",
    fontWeight: 600,
    color: "#d3222a",
    fontSize: "18px",
    textAlign: "right",
    paddingRight: "8px",
  },
});

const Invoice = React.forwardRef(({ currentOrder }, ref) => {
  console.log("currentOrder?.createdAt", currentOrder);
  return (
    <div ref={ref}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.flexCol}>
              <img src="/images/logo-sandhiya.png" width="300" />
            </Text>
            <Text style={{ color: "#d3222a", fontSize: 50, fontWeight: 700 }}>
              Invoice
            </Text>
          </View>
          <View style={styles.addressSection}>
            <View style={styles.flexCol}>
              <Text>No. 77, 53rd Street,</Text> <Text>Ashok Nagar,</Text>{" "}
              <Text>Chennai 600 083.</Text>
              <Text>044-24896979</Text>
            </View>
            <View style={styles.invoiceDet}>
              <View style={{ ...styles.flex }}>
                <Text style={styles.invoiceText}>Invoice #</Text>
                <Text style={{ width: "50%" }}>
                  {currentOrder?.invoiceNumber}
                </Text>
              </View>
              <View style={{ ...styles.flex }}>
                <Text style={styles.invoiceText}>Date</Text>
                <Text style={{ width: "50%" }}>
                  {moment(currentOrder?.createdAt).format("DD/MM/yyyy")}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.addressSection}>
            <View
              style={{
                ...styles.flexCol,
                maxWidth: "50%",
                paddingRight: "20px",
              }}
            >
              <Text style={{ fontWeight: 600, fontSize: 18, color: "#d3222a" }}>
                Ship To:
              </Text>
              <Text>{currentOrder?.shippingAddress?.name}</Text>{" "}
              <Text>{currentOrder?.shippingAddress?.line1}</Text>{" "}
              <Text>{currentOrder?.shippingAddress?.line2}</Text>{" "}
              <Text>
                {currentOrder?.shippingAddress?.city} -{" "}
                {currentOrder?.shippingAddress?.pincode}{" "}
              </Text>
              <Text>
                {currentOrder?.shippingAddress?.state},{" "}
                {currentOrder?.shippingAddress?.country}
              </Text>
              <Text>
                {currentOrder?.contactNumber} |{" "}
                <Text style={{ color: "#d3222a" }}>
                  {currentOrder?.contactEmail}
                </Text>
              </Text>
            </View>
            <View style={{ border: "1px solid #2a2929" }}></View>
            <View
              style={{
                ...styles.flexCol,
                maxWidth: "50%",
                paddingLeft: "20px",
              }}
            >
              <Text style={{ fontWeight: 600, fontSize: 18, color: "#d3222a" }}>
                Billing Address:
              </Text>
              <Text>{currentOrder?.billingAddress?.line1}</Text>{" "}
              <Text>{currentOrder?.billingAddress?.line2}</Text>{" "}
              <Text>
                {currentOrder?.billingAddress?.city} -{" "}
                {currentOrder?.billingAddress?.pincode}{" "}
              </Text>
              <Text>
                {currentOrder?.billingAddress?.state},{" "}
                {currentOrder?.billingAddress?.country}
              </Text>
              <Text>
                {currentOrder?.contactNumber} |{" "}
                <Text style={{ color: "#d3222a" }}>
                  {currentOrder?.contactEmail}
                </Text>
              </Text>
            </View>
          </View>
          <View style={{ ...styles.flexCol, marginTop: "4rem" }}>
            <View
              style={{
                ...styles.flex,
                width: "100%",
                borderTop: "2px solid #2a2929",
                borderBottom: "2px solid #2a2929",
                fontWeight: 600,
                padding: "1rem",
                color: "#d3222a",
                fontSize: 14,
              }}
            >
              <Text style={{ width: "10%" }}>S.No</Text>
              <Text style={{ width: "60%" }}>Title</Text>
              <Text style={{ width: "10%" }}>Quantity</Text>
              <Text style={{ width: "10%" }}>Unit Price</Text>
              <Text style={{ width: "10%" }}>Total Price</Text>
            </View>
            {currentOrder?.items?.map((order, i) => (
              <View
                style={{
                  ...styles.flex,
                  width: "100%",
                  // borderTop: "1px solid #d3222a",
                  // borderBottom: "1px solid #d3222a",
                  padding: "0.5rem 1rem",
                  fontWeight: 500,
                }}
              >
                <Text style={{ width: "10%" }}>{i + 1}</Text>
                <Text style={{ width: "60%" }}>{order?.item?.title}</Text>
                <Text style={{ width: "10%" }}>{order?.count}</Text>
                <Text style={{ width: "10%" }}>₹ {order?.item?.price}</Text>
                <Text style={{ width: "10%" }}>₹ {order?.price}</Text>
              </View>
            ))}
          </View>
          {currentOrder?.totalAmount < 549 && (
            <View
              style={{
                ...styles.flex,
                width: "100%",
                fontWeight: 600,
                padding: "0.5rem 1rem",
                borderTop: "2px solid #2a2929",
              }}
            >
              <Text style={{ width: "10%" }}></Text>
              <Text style={{ width: "70%" }}></Text>
              <Text style={{ width: "10%", borderBottom: "2px solid #2a2929" }}>
                Shipping
              </Text>
              <Text style={{ width: "10%", borderBottom: "2px solid #2a2929" }}>
                ₹ 50
              </Text>
            </View>
          )}
          <View
            style={{
              ...styles.flex,
              width: "100%",
              borderBottom: "2px solid #2a2929",
              fontWeight: 600,
              padding: "0.5rem 1rem",
            }}
          >
            <Text style={{ width: "10%" }}></Text>
            <Text style={{ width: "70%" }}></Text>
            <Text style={{ width: "10%" }}>Total</Text>
            <Text style={{ width: "10%" }}>₹ {currentOrder?.totalAmount}</Text>
          </View>
        </Page>
      </Document>
    </div>
  );
});

export default Invoice;
