import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import "./AddBooks.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useHistory } from "react-router";
import { CLOUDINARY_CLOUD, SERVER_BASE_URL } from "../../config/config";
import Dropzone, { useDropzone } from "react-dropzone";
import star from "../../assets/img/star.png";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import AppPagination from "helpers/appPagination";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function AddAuthors() {
  const history = useHistory();
  const [data, setData] = React.useState({});
  const [text, setText] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [name, setName] = React.useState("");
  const [age, setAge] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [authors, setAuthors] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [trending, setTrending] = React.useState("");
  const [featured, setFeatured] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [page, setPage] = useState(1);

  const [add, setAdd] = useState("");
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [del, setDel] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(false);
  const [currentEditAuthor, setCurrentEditAuthor] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [file, setFile] = useState([]);
  const [added, setAdded] = useState(false);
  const [search, setSearch] = useState("");
  const [imageDelete, setImageDelete] = useState(false);
  const [image, setImage] = React.useState();

  const handleBulkAdd = async (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);

    const precheck = await axios.post(
      `${SERVER_BASE_URL}/v1/app/precheck-author-bulk`,
      formData,
      config
    );

    await axios.post(
      `${SERVER_BASE_URL}/v1/app/upload-author-bulk`,
      {
        data: precheck.data.payload,
      },
      config
    );
  };

  const uploadImage = (files) => {
    setImage(files[0]);
  };

  const handleDrop = async (acceptedFiles) => {
    setFile(acceptedFiles);
    setFileNames(acceptedFiles[0].name);
    setAdded(true);
  };

  useEffect(() => {
    setLoading(true);
    getAuthors();
  }, [edit, add, del, search, page]);

  const onEditClick = (id, name, en, isFamous, popularity, age, image) => {
    setEdit(true);
    setCurrentEditAuthor({ id, name, en, isFamous, popularity, age, image });
  };

  const onDeleteClick = (id) => {
    setDel(true);
    setCurrentDeleteId(id);
    handleDeleteClick(id);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setEdit(false);
    setData({});
  };

  const getAuthors = () => {
    axios
      .get(
        `${SERVER_BASE_URL}/v1/app/authors?sortBy=popularity:asc,name&paginate=true&page=${page}&search=${search}`,
        config
      )
      .then((res) => {
        setAuthors(res.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to Get Authors!");
        setLoading(false);
      });
  };

  const handleInputChange = (e, type, name) => {
    let obj = data;
    if (type === "select") {
      obj = { ...obj, [name]: e.value };
    } else if (type === "multiSelect") {
      console.log(e);
      obj = { ...obj, [name]: e.map((item) => item.value) };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    setData(obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(image);

    console.log(data);

    const fd = new FormData();
    fd.append("file", image);

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((d) => fd.append(key, d));
        return;
      }
      fd.append(key, data[key]);
    });

    await axios
      .post(`${SERVER_BASE_URL}/v1/app/add-author`, fd, config)
      .then((res) => {
        setAdd(res);
        toast.success("Success!");
      })
      .catch((err) => toast.error("Unable to Add Author!"));
    setImage();

    setData({});
  };

  const handleEditAuthor = async (e, id) => {
    e.preventDefault();
    console.log("vishnu the boss", data);
    const fd = new FormData();

    if (image) {
      fd.append("file", image);
    }

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((d) => fd.append(key, d));
        return;
      }
      fd.append(key, data[key]);
    });

    await axios
      .patch(`${SERVER_BASE_URL}/v1/app/author/${id}`, fd, config)
      .then(() => {
        toast.success("Success!");
      })
      .catch((err) => toast.error("Unable to Edit Author!"));

    setImage();

    setData({});

    setEdit(false);
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    await axios
      .post(`${SERVER_BASE_URL}/v1/app/author/${id}`, {}, config)
      .then(() => {
        toast.success("Deleted!");
      })
      .catch((err) => console.log(err));
    setDel(false);
  };

  const isOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const onImageDelete = async (event, image, id) => {
    event.preventDefault();
    console.log(id);
    await axios
      .patch(
        `${SERVER_BASE_URL}/v1/app/author/image/${id}`,
        {
          image,
        },
        config
      )
      .then(() => {
        toast.success("Deleted!");
      })
      .catch((err) => toast.error("Unable to Delete Author Image!"));
    setImageDelete(true);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Add Authors</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Name</label>
                        <Input
                          placeholder="Author Name"
                          type="text"
                          name="name"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Is Famous?</label>
                        <Select
                          options={isOption}
                          name="isFamous"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isFamous")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Name in English</label>
                        <Input
                          placeholder="Name in English"
                          type="text"
                          name="en"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Age</label>
                        <Input
                          placeholder="Age"
                          type="text"
                          name="age"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>{" "}
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Popularity</label>
                        <Input
                          type="number"
                          name="popularity"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      {" "}
                      <FormGroup>
                        <label for="file">Choose a file</label>
                        <Input
                          type="file"
                          id="file"
                          class="inputfile"
                          name="image"
                          onChange={(e) => uploadImage(e.target.files)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Add Author
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>{" "}
          {edit && (
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Edit {currentEditAuthor?.name}</CardTitle>
                </CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) => handleEditAuthor(e, currentEditAuthor?.id)}
                  >
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Name</label>
                          <Input
                            placeholder="Author Name"
                            type="text"
                            name="name"
                            defaultValue={currentEditAuthor?.name}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="3">
                        <FormGroup>
                          <label>Name in English</label>
                          <Input
                            placeholder="Name in English"
                            type="text"
                            name="en"
                            defaultValue={currentEditAuthor?.en}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Age</label>
                          <Input
                            placeholder="Age"
                            type="text"
                            name="age"
                            defaultValue={currentEditAuthor?.age}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Popularity</label>
                          <Input
                            type="number"
                            name="popularity"
                            defaultValue={currentEditAuthor?.popularity}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="3">
                        <FormGroup>
                          <label>Is Famous?</label>
                          <Select
                            options={isOption}
                            name="isFamous"
                            defaultValue={{
                              label:
                                currentEditAuthor?.isFamous === true
                                  ? "Yes"
                                  : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isFamous")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label for="file">Choose a file</label>
                          <Input
                            type="file"
                            id="file"
                            class="inputfile"
                            name="image"
                            onChange={(e) => uploadImage(e.target.files)}
                          />
                        </FormGroup>
                        <br></br>
                        <div>Current Image:</div>
                        <img
                          width="120"
                          src={!imageDelete ? currentEditAuthor?.image : ""}
                        ></img>
                        <br></br>{" "}
                        <button
                          onClick={(e) =>
                            onImageDelete(
                              e,
                              currentEditAuthor?.image,
                              currentEditAuthor?.id
                            )
                          }
                        >
                          X Delete Image
                        </button>
                      </Col>
                    </Row>

                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Edit Author
                        </Button>
                      </div>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="button"
                          onClick={(e) => handleCancelEdit(e)}
                        >
                          Cancel Edit
                        </Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          )}{" "}
          <Col md="12">
            <Card style={{ minHeight: "700px" }}>
              <CardHeader>
                <CardTitle tag="h4">Authors Database</CardTitle>
                <input
                  id="search"
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                  required
                />
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th></th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>ID</th>
                      <th>Popularity</th>
                      <th>Age</th>
                      <th>No. of notebooks</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading
                      ? authors?.results?.map((author) => (
                          <tr>
                            <td>
                              {author?.isFamous ? (
                                <img width={"30"} src={star}></img>
                              ) : (
                                ""
                              )}
                            </td>{" "}
                            <td>
                              {author?.image && (
                                <img src={author?.image} width={"30px"}></img>
                              )}
                            </td>
                            <td>
                              {" "}
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() =>
                                  onEditClick(
                                    author?.id,
                                    author?.name,
                                    author?.en,
                                    author?.isFamous,
                                    author?.popularity,
                                    author?.age,

                                    author?.image
                                  )
                                }
                              >
                                {author?.name}
                              </div>
                            </td>
                            <CopyToClipboard
                              text={author?.id}
                              onCopy={onCopyText}
                            >
                              <td
                                onChange={(event) =>
                                  setText(event.target.value)
                                }
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#51cbce",
                                  }}
                                >
                                  {author?.id}{" "}
                                </div>
                              </td>
                            </CopyToClipboard>
                            <td>{author?.popularity}</td>
                            <td>{author?.age}</td>
                            <td>{author?.noOfBooks ? author?.noOfBooks : 0}</td>
                            <td>
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onDeleteClick(author?.id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))
                      : "Loading..."}
                  </tbody>
                </Table>
                <AppPagination setPage={setPage} {...authors} />
              </CardBody>
            </Card>
          </Col>
          <Col md="12">
            {" "}
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Bulk Add Authors</CardTitle>
              </CardHeader>
              <Dropzone
                onDrop={handleDrop}
                accept="image/*"
                minSize={1024}
                maxSize={3072000}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? "accept"
                    : isDragReject
                    ? "reject"
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <span>{isDragActive ? "📂" : "📁"}</span>
                      {added
                        ? fileNames
                        : "Drag'n'drop images, or click to select files"}
                    </div>
                  );
                }}
              </Dropzone>
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    onClick={() => handleBulkAdd(file)}
                    className="btn-round"
                    color="primary"
                    type="submit"
                  >
                    Bulk Add
                  </Button>
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddAuthors;
