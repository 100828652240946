import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CLOUDINARY_CLOUD, SERVER_BASE_URL } from "../../config/config";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Banner() {
  const history = useHistory();
  const [data, setData] = React.useState({});
  const [text, setText] = useState("");
  const [name, setName] = React.useState("");
  const [banner, setBanner] = React.useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const [add, setAdd] = useState("");
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(false);
  const [currentEditCategoryId, setCurrentEditCategoryId] = useState();
  const [currentEdit, setCurrentEdit] = useState("");
  const [fileNames, setFileNames] = useState([]);
  const [file, setFile] = useState([]);
  const [added, setAdded] = useState(false);
  const [imageDelete, setImageDelete] = useState(false);
  const [image, setImage] = React.useState();
  const [search, setSearch] = useState("");

  useEffect(() => {
    getBanner();
  }, [edit, add, del, search]);

  const onEditClick = (banner) => {
    setEdit(true);
    setCurrentEdit(banner);
  };

  const onDeleteClick = (id) => {
    setDel(true);
    setCurrentDeleteId(id);
    handleDeleteClick(id);
  };

  const getBanner = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/admin/banner`, config)
      .then((res) => setBanner(res.data))
      .catch((err) => console.log(err));
  };

  const uploadImage = (files) => {
    setImage(files[0]);
  };

  const handleInputChange = (e, type, name) => {
    let obj = data;
    if (type === "select") {
      obj = { ...obj, [name]: e.value };
    } else if (type === "multiSelect") {
      console.log(e);
      obj = { ...obj, [name]: e.map((item) => item.value) };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    setData(obj);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const fd = new FormData();
    fd.append("file", image);

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((d) => fd.append(key, d));
        return;
      }
      fd.append(key, data[key]);
    });

    await axios
      .post(`${SERVER_BASE_URL}/v1/admin/banner`, fd, config)
      .then((res) => {
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAdd(true);
      })
      .catch((err) =>
        toast.error("Unable to Add Banner!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
    setImage();

    setData({});
  };

  const handleEditBanner = async (e, id) => {
    e.preventDefault();

    const fd = new FormData();

    if (image) {
      fd.append("file", image);
    }

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((d) => fd.append(key, d));
        return;
      }
      fd.append(key, data[key]);
    });

    await axios
      .patch(`${SERVER_BASE_URL}/v1/admin/banner/${id}`, fd, config)
      .then((res) => {
        setEdit(false);
        toast.success("Success!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });

    setImage();

    setData({});
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    await axios
      .delete(`${SERVER_BASE_URL}/v1/admin/banner/${id}`, config)
      .then(() => {
        toast.success("Deleted!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) =>
        toast.error("Unable to DElete Banner!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      );
    setDel(false);
  };

  const isBoolean = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Add Banner Image</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Redirection Link</label>
                        <Input
                          placeholder="Redirection Link"
                          type="text"
                          name="link"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label for="file">Choose Image</label>
                        <Input
                          type="file"
                          id="file"
                          class="inputfile"
                          name="image"
                          onChange={(e) => uploadImage(e.target.files)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col className="pl-3" md="4">
                      <FormGroup>
                        <label>Is Active?</label>
                        <Select
                          options={isBoolean}
                          name="isActive"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isActive")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Is Slider?</label>
                        <Select
                          options={isBoolean}
                          name="isSlider"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isSlider")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Is News Slider?</label>
                        <Select
                          options={isBoolean}
                          name="isNewsSlider"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isNewsSlider")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-3">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Add Banner
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          {edit && (
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Edit {currentEdit?.image}</CardTitle>
                </CardHeader>
                <CardBody>
                  <form onSubmit={(e) => handleEditBanner(e, currentEdit?.id)}>
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Redirection Link</label>
                          <Input
                            placeholder="Redirection Link"
                            type="text"
                            name="link"
                            defaultValue={currentEdit?.link}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>{" "}
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label for="file">Choose Image</label>
                          <Input
                            type="file"
                            id="file"
                            class="inputfile"
                            name="image"
                            onChange={(e) => uploadImage(e.target.files)}
                          />
                        </FormGroup>
                        <br></br>
                        <div>Current Image:</div>
                        <img width={100} src={currentEdit?.image}></img>
                      </Col>
                    </Row>{" "}
                    <Row>
                      <Col className="pl-3" md="4">
                        <FormGroup>
                          <label>Is Active?</label>
                          <Select
                            options={isBoolean}
                            name="isActive"
                            defaultValue={{
                              label:
                                currentEdit?.isActive === true ? "Yes" : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isActive")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Is Slider?</label>
                          <Select
                            options={isBoolean}
                            name="isSlider"
                            defaultValue={{
                              label:
                                currentEdit?.isSlider === true ? "Yes" : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isSlider")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Is News Slider?</label>
                          <Select
                            options={isBoolean}
                            name="isNewsSlider"
                            defaultValue={{
                              label:
                                currentEdit?.isSlider === true ? "Yes" : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isNewsSlider")
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-3">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Edit Banner
                        </Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          )}
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Banner Database</CardTitle>
              </CardHeader>{" "}
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Image</th>
                      <th>Redirection Link</th>
                      <th>isActive?</th>
                      <th>isSlider?</th>
                    </tr>
                  </thead>
                  <tbody>
                    {console.log(banner)}
                    {banner?.payload?.map((banner) => (
                      <tr>
                        <td>
                          {" "}
                          <div
                            style={{ cursor: "pointer", color: "#51cbce" }}
                            onClick={() => onEditClick(banner)}
                          >
                            <img
                              width={100}
                              src={banner?.image ? banner?.image : "N/A"}
                            ></img>
                          </div>
                        </td>{" "}
                        <CopyToClipboard text={banner?.id}>
                          <td onChange={(event) => setText(event.target.value)}>
                            <div
                              style={{ cursor: "pointer", color: "#51cbce" }}
                            >
                              {banner?.link}
                            </div>
                          </td>
                        </CopyToClipboard>
                        <td>{banner?.isActive ? "Yes" : "No"}</td>
                        <td>{banner?.isSlider ? "Yes" : "No"}</td>
                        <td className="">
                          <div
                            style={{ cursor: "pointer", color: "#51cbce" }}
                            onClick={() => onDeleteClick(banner?.id)}
                          >
                            Delete
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Banner;
