import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import { SERVER_BASE_URL } from "../../config/config";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import AppPagination from "helpers/appPagination";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Users() {
  const [data, setData] = React.useState({});
  const [page, setPage] = useState(1);
  const [users, setUsers] = React.useState("");

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/users`, config)
      .then((res) => setUsers(res.data))
      .catch((err) => toast.error("Unable Get Users Data!"));
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Users Database</CardTitle>
              </CardHeader>{" "}
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users &&
                      users?.results?.map((user) => (
                        <tr>
                          <td>{user?.name ? user?.name : "Unnamed"}</td>
                          <td>{user?.email ? user?.email : "N/A"}</td>
                          <td>{user?.role ? user?.role : "N/A"}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {/* <AppPagination setPage={setPage} {...users} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Users;
