import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import "./AddBooks.css";
import { CLOUDINARY_CLOUD, SERVER_BASE_URL } from "../../config/config";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import CreatableSelect from "react-select/creatable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import AppPagination from "helpers/appPagination";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function AllBooks() {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState("");
  const [stock, setStock] = React.useState("");
  const [authors, setAuthors] = React.useState("");
  const [categoriesChosen, setCategoriesChosen] = React.useState("");
  const [authorChosen, setAuthorChosen] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [trending, setTrending] = React.useState("");
  const [featured, setFeatured] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [books, setBooks] = React.useState("");
  const [translators, setTranslators] = React.useState([]);
  const [imageDelete, setImageDelete] = useState(false);
  const [page, setPage] = useState(1);

  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(false);
  const [currentEditBookId, setCurrentEditBookId] = useState();
  const [currentEdit, setCurrentEdit] = useState("");
  const [search, setSearch] = useState("");
  const [image, setImage] = React.useState();

  useEffect(() => {
    getCategories();
    getAuthors();
    getTranslators();
  }, [edit, del]);

  useEffect(() => {
    setLoading(true);
    getBook({ search, page });
  }, [edit, del, search, page]);

  const onEditClick = (book) => {
    setEdit(true);
    setCurrentEditBookId(book.id);
    setCurrentEdit(book);
  };

  const onDeleteClick = (id) => {
    setDel(true);
    setCurrentDeleteId(id);
    handleDeleteClick(id);
  };

  const getCategories = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/categories?search=`, config)
      .then((res) => setCategories(res.data.payload))
      .catch((err) => toast.error("Unable Get Categories Data!"));
  };

  const getAuthors = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/authors?search=`, config)
      .then((res) => setAuthors(res.data.payload))
      .catch((err) => toast.error("Unable Get Authors Data!"));
  };

  const getTranslators = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/translators?search=`, config)
      .then((res) => setTranslators(res.data.payload))
      .catch((err) => toast.error("Unable Get Authors Data!"));
  };
  console.log(categories);

  const uploadImage = (files) => {
    setImage(files[0]);
  };

  const handleInputChange = (e, type, name) => {
    let obj = data;
    if (type === "select") {
      obj = { ...obj, [name]: e.value };
    } else if (type === "multiSelect") {
      console.log(e);
      obj = { ...obj, [name]: e };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    setData(obj);
  };

  console.log(categoriesChosen);

  const handleEditSubmit = async (e, id) => {
    e.preventDefault();

    const fd = new FormData();

    if (image) {
      fd.append("file", image);
    }

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((d) => fd.append(key, d));
        return;
      }
      fd.append(key, data[key]);
    });

    // const value = data.category
    //   ? { ...data, category: data.category.map((cat) => cat.value) }
    //   : { ...data };

    console.log("EDIT", data);

    await axios
      .patch(`${SERVER_BASE_URL}/v1/app/book/${id}`, fd, config)
      .then((res) => {
        toast.success("Success!");
        setEdit(false);
      })
      .catch((err) => toast.error("Unable Edit Books!"));
    setImage();

    setData({});
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    await axios
      .post(`${SERVER_BASE_URL}/v1/app/book/${id}`, {}, config)
      .then(() => toast.success("Deleted!"))
      .catch((err) => toast.error("Unable Delete Books!"));
    setDel(false);
  };

  const isTrendingOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const isFeaturedOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const getBook = ({ search, page }) => {
    axios
      .get(
        `${SERVER_BASE_URL}/v1/app/books?paginate=true&search=${search}&page=${page}&sortBy=title:asc`,
        config
      )
      .then((res) => {
        setBooks(res.data.payload);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setEdit(false);
    setImage();

    setData({});
  };

  const onImageDelete = async (event, image, id) => {
    event.preventDefault();
    console.log(id);
    await axios
      .patch(
        `${SERVER_BASE_URL}/v1/app/book/image/${id}`,
        {
          image,
        },
        config
      )
      .then(() => {
        toast.success("Deleted!");
      })
      .catch((err) => toast.error("Unable to Delete Book Image!"));
    setImageDelete(true);
  };

  console.log(books);

  return (
    <>
      <div className="content">
        <Row>
          {edit && (
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Edit {currentEdit.title}</CardTitle>
                </CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) => handleEditSubmit(e, currentEditBookId)}
                  >
                    <Row>
                      <Col className="pr-1" md="3">
                        <FormGroup>
                          <label>Title</label>
                          <Input
                            placeholder="Book Title"
                            type="text"
                            name="title"
                            defaultValue={currentEdit?.title}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label>Price</label>
                          <Input
                            placeholder="Book Price"
                            type="number"
                            name="price"
                            defaultValue={currentEdit?.price}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label>Discount</label>
                          <Input
                            placeholder="Discount Price"
                            type="number"
                            name="discount"
                            defaultValue={currentEdit?.discount}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="3">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Stock</label>
                          <Input
                            placeholder="No of Stock"
                            type="number"
                            name="stock"
                            defaultValue={currentEdit?.stock}
                            onChange={handleInputChange}
                            min={1}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Brand</label>
                          <Input
                            type="text"
                            name="brand"
                            defaultValue={currentEdit?.brand}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Author</label>
                          <Select
                            options={[
                              { label: "N/A", value: null },
                              ...authors?.map((author) => ({
                                label: author.name,
                                value: author.id,
                              })),
                            ]}
                            name="author"
                            defaultValue={{
                              label: currentEdit?.author?.name,
                              value: currentEdit?.author?.id,
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "author")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label>Translator</label>
                          <Select
                            options={[
                              { label: "N/A", value: null },
                              ...translators?.map((translator) => ({
                                label: translator.name,
                                value: translator.id,
                              })),
                            ]}
                            name="translator"
                            defaultValue={{
                              label: currentEdit?.translator?.name,
                              value: currentEdit?.translator?.id,
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "translator")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Category</label>
                          <Select
                            isMulti
                            options={categories?.map((category) => ({
                              label: category.name,
                              value: category.id,
                            }))}
                            value={
                              data && data.category
                                ? data.category
                                : currentEdit?.category?.map((cat) => ({
                                    label: cat?.name,
                                    value: cat?.id,
                                  }))
                            }
                            name="category"
                            onChange={(e) =>
                              handleInputChange(e, "multiSelect", "category")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Tags</label>
                          <Input
                            type="text"
                            name="tags"
                            defaultValue={currentEdit?.tags}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">
                            Published Year
                          </label>
                          <Input
                            type="number"
                            name="publishedYear"
                            defaultValue={currentEdit?.publishedYear}
                            onChange={(e) => handleInputChange(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pl-3" md="6">
                        <FormGroup>
                          <label>Is New Arrival?</label>
                          <Select
                            options={isTrendingOptions}
                            name="isNewArrival"
                            defaultValue={{
                              label:
                                currentEdit?.isNewArrival === true
                                  ? "Yes"
                                  : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isNewArrival")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="6">
                        <FormGroup>
                          <label>Is Best Seller?</label>
                          <Select
                            options={isFeaturedOptions}
                            name="isBestSeller"
                            defaultValue={{
                              label:
                                currentEdit?.isBestSeller === true
                                  ? "Yes"
                                  : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isBestSeller")
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      {" "}
                      <Col className="pl-3" md="4">
                        <FormGroup>
                          <label>Is Old Author Choice?</label>
                          <Select
                            options={isTrendingOptions}
                            name="isOldAuthorChoice"
                            defaultValue={{
                              label:
                                currentEdit?.isOldAuthorChoice === true
                                  ? "Yes"
                                  : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(
                                e,
                                "select",
                                "isOldAuthorChoice"
                              )
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Is Trending?</label>
                          <Select
                            options={isTrendingOptions}
                            name="isTrending"
                            defaultValue={{
                              label:
                                currentEdit?.isTrending === true ? "Yes" : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isTrending")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pl-1" md="4">
                        <FormGroup>
                          <label>Is Featured?</label>
                          <Select
                            options={isFeaturedOptions}
                            name="isFeatured"
                            defaultValue={{
                              label:
                                currentEdit?.isFeatured === true ? "Yes" : "No",
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "isFeatured")
                            }
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="4">
                        <FormGroup>
                          <label for="file">Choose a file</label>
                          <Input
                            type="file"
                            id="file"
                            class="inputfile"
                            name="image"
                            onChange={(e) => uploadImage(e.target.files)}
                          />
                          <br></br>
                          <div>Current Image:</div>
                          <img
                            width="120"
                            src={!imageDelete ? currentEdit?.image : ""}
                          ></img>
                        </FormGroup>
                        <button
                          onClick={(e) =>
                            onImageDelete(
                              e,
                              currentEdit?.image,
                              currentEdit?.id
                            )
                          }
                        >
                          X Delete Image
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label>Description</label>
                          <Input
                            type="textarea"
                            name="description"
                            defaultValue={currentEdit?.description}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Edit Book
                        </Button>
                      </div>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="button"
                          onClick={(e) => handleCancelEdit(e)}
                        >
                          Cancel Edit
                        </Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          )}

          <Col md="12">
            <Card style={{ minHeight: "700px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardHeader>
                  <CardTitle tag="h4">Books</CardTitle>
                </CardHeader>{" "}
                <input
                  style={{ width: "50%", marginRight: "1rem" }}
                  id="search"
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setPage(1);
                  }}
                  required
                />
              </div>
              <CardBody>
                <Table responsive style={{ width: "1800px" }}>
                  <thead className="text-primary">
                    <tr>
                      <th width="600">Title</th>
                      <th>Brand</th>
                      <th>Author</th>
                      <th>Translator</th>
                      <th>Stock Left</th>
                      <th className="">Price</th>
                      <th className="">Discount</th>
                      <th className="">Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ minHeight: "550px" }}>
                    {!loading
                      ? books &&
                        books?.results?.map((book) => (
                          <tr>
                            <td>
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onEditClick(book)}
                              >
                                {book?.title ? book?.title : "Unnamed"}
                              </div>
                            </td>
                            <td>{book?.brand ? book?.brand : "Unnamed"}</td>
                            <td>
                              {book?.author?.name
                                ? book?.author?.name
                                : "Unnamed"}
                            </td>
                            <td>
                              {book?.translator?.name
                                ? book?.translator?.name
                                : "Unnamed"}
                            </td>
                            <td>
                              {book?.stock ? book?.stock : "No Stock Left!"}
                            </td>
                            <td className="">
                              ₹ {book?.price ? book?.price : "N/A"}
                            </td>
                            <td className="">
                              ₹ {book?.discount ? book?.discount : "N/A"}
                            </td>
                            <td className="">
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onDeleteClick(book?.id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))
                      : "Loading..."}
                  </tbody>
                </Table>
              </CardBody>
              <AppPagination setPage={setPage} {...books} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AllBooks;
