import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SERVER_BASE_URL } from "../../config/config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { toast } from "react-toastify";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Settings() {
  const [data, setData] = React.useState("");
  const [limit, setLimit] = React.useState([]);

  const [add, setAdd] = useState("");
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [password, setPassword] = useState(false);

  useEffect(() => {
    getLimit();
  }, [edit, add, del]);

  const getLimit = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/settings`, config)
      .then((res) => setLimit(res.data))
      .catch((err) => console.log(err));
  };

  const handleInputChange = (e) => {
    let obj = data;

    obj = { ...obj, [e.target.name]: e.target.value };

    setData(obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .patch(`${SERVER_BASE_URL}/v1/app/settings  `, data, config)
      .then((res) => {
        setAdd(res);
        toast.success("Password Changed");
      })
      .catch((err) => console.log(err));
  };

  const handleReset = async (e) => {
    e.preventDefault();

    await axios
      .post(
        `${SERVER_BASE_URL}/v1/admin/reset-password  `,
        { password },
        config
      )
      .then((res) => {
        setAdd(res);
        toast.success("Password Changed");
      })
      .catch((err) => toast.error("Something Went Wrong"));
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Limits</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>New Arrivals</label>
                        <Input
                          type="number"
                          name="newArrivals"
                          defaultValue={limit?.payload?.newArrivals}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>TRENDING NOW </label>
                        <Input
                          type="number"
                          name="trendingCount"
                          defaultValue={limit?.payload?.trendingCount}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>BEST SELLERS</label>
                        <Input
                          type="number"
                          name="bestSellersCount"
                          defaultValue={limit?.payload?.bestSellersCount}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Discount Items</label>
                        <Input
                          type="number"
                          name="discountItems"
                          defaultValue={limit?.payload?.discountItems}
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-3">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Change Password</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleReset}>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>New Password</label>
                        <Input
                          type="password"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-3">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Settings;
