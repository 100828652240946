/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { useHistory } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SERVER_BASE_URL } from "../../config/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactToPrint from "react-to-print";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import AppPagination from "helpers/appPagination";
import Invoice from "./Invoice";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Reports() {
  const [reports, setReports] = useState();
  const [status, setStatus] = useState();
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [page, setPage] = useState(1);
  const [currentOrder, setCurrentOrder] = useState();
  const componentRef = useRef();
  useEffect(() => {
    getReports();
  }, [status]);

  const handleDropdown = (e, id) => {
    console.log(e.target.value);
    axios
      .patch(
        `${SERVER_BASE_URL}/v1/admin/update-order/${id}`,
        { status: e.target.value },
        config
      )
      .then((res) => setStatus(res))
      .catch((err) => console.log(err));
  };

  const getReports = () => {
    axios
      .get(
        `${SERVER_BASE_URL}/v1/admin/all-orders?page=${page}&search=`,
        config
      )
      .then((res) => setReports(res.data.payload))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getReports();
  }, [page]);

  const handleOnChange = (e, date) => {
    if (date === "startDate") {
      setStartDate(e);
    }
    if (date === "endDate") {
      setEndDate(e);
    }

    console.log(moment(e).format());
  };

  const handleDisplayDetails = (report) => {
    console.log("report", report);
    setCurrentOrder(report);
    setModal(true);
  };

  const submitDate = () => {
    if (startDate && endDate) {
      axios
        .get(
          `${SERVER_BASE_URL}/v1/admin/all-orders?startDate=${moment(
            startDate
          ).format()}&endDate=${moment(endDate).format()}`,
          config
        )
        .then((res) => setReports(res.data.payload))
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">All orders</CardTitle>
                <Row>
                  <Col md="2">
                    From
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) =>
                      //   setStartDate(new Date(date).toISOString())
                      // }
                      // onChange={(date) => setStartDate(date)}
                      onChange={(e) => handleOnChange(e, "startDate")}
                    />
                  </Col>
                  <Col md="2">
                    To
                    <DatePicker
                      selected={endDate}
                      // onChange={(date) =>
                      //   setEndDate(new Date(date).toISOString())
                      // }
                      // onChange={(date) => setStartDate(date)}
                      onChange={(e) => handleOnChange(e, "endDate")}
                    />
                  </Col>
                  <Col md="2">
                    <Button onClick={submitDate}>Go</Button>
                  </Col>
                </Row>
              </CardHeader>{" "}
              {/* <input
                id="search"
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => setSearch(e.target.value)}
                required
              /> */}
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>Payment ID</th>
                      <th>Phone No</th>
                      <th>Purchased On</th>
                      <th>Purchased Status</th>
                      <th>Total Amount</th>
                      {/* <th>Shipping Address</th>
                      <th>Billing Address</th> */}
                      {/* <th>Items</th> */}
                      <th>Status</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {reports?.results?.map((report) => (
                      <tr>
                        <td>{report?.userId?.name}</td>
                        <td className="">{report?.paymentId ?? "N/A"}</td>
                        <td className="">{report?.phoneNumber ?? "N/A"}</td>
                        <td className="">
                          {moment(report?.createdAt).format("DD/MM/yyyy")}
                        </td>
                        <td className="">{report?.paymentStatus}</td>
                        <td className="">₹ {report?.totalAmount}</td>
                        <td>
                          <select
                            value={report?.status}
                            onChange={(e) => handleDropdown(e, report?.id)}
                          >
                            <option value="Pending">Pending</option>
                            <option value="Dispatched">Dispatched</option>
                            <option value="Delivered">Delivered</option>
                          </select>
                        </td>
                        <td>
                          <button
                            className="show_det_btn"
                            onClick={() => handleDisplayDetails(report)}
                          >
                            Show Details
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              <AppPagination setPage={setPage} {...reports} />
            </Card>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={() => setModal(!modal)}>
          <ModalHeader toggle={() => setModal(!modal)}>Invoice</ModalHeader>
          <ModalBody>
            <Invoice currentOrder={currentOrder} ref={componentRef} />
          </ModalBody>
          <ModalFooter>
            <ReactToPrint
              trigger={() => <button className="print_btn">Print</button>}
              content={() => componentRef.current}
            />
            <Button color="secondary" onClick={() => setModal(!modal)}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default Reports;
