/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import axios from "axios";
import { SERVER_BASE_URL } from "../config/config";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { PolarArea } from "react-chartjs-2";

// react plugin used to create charts
// import { Line, Pie } from "react-chartjs-2";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// core components
import {
  dashboard24HoursPerformanceChart,
  dashboardEmailStatisticsChart,
  dashboardNASDAQChart,
} from "variables/charts.js";
import { toast } from "react-toastify";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

function Dashboard() {
  const [authors, setAuthors] = React.useState("");
  const [categories, setCategories] = React.useState("");
  const [stats, setStats] = React.useState("");
  const [delivery, setDelivery] = React.useState("");
  const [payment, setPayment] = React.useState("");

  useEffect(() => {
    getCategories();
    getAuthors();
    getDelivery();
    getPayment();
    getStats();
  }, []);

  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const getStats = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/admin/dashboard-stats`, config)
      .then((res) => setStats(res?.data?.payload))
      .catch((err) => toast.error("Unable Get Stats!"));
  };

  const getCategories = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/admin/books-category-chart`, config)
      .then((res) => setCategories(res?.data?.payload))
      .catch((err) => toast.error("Unable Get Category Stats!"));
  };

  const getPayment = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/admin/orders-payment-chart`, config)
      .then((res) => setPayment(res?.data?.payload))
      .catch((err) => toast.error("Unable Get Order Payment Stats!"));
  };

  const getDelivery = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/admin/orders-delivery-chart`, config)
      .then((res) => setDelivery(res?.data?.payload))
      .catch((err) => toast.error("Unable Get Order Delivery Stats!"));
  };

  const getAuthors = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/admin/books-author-chart`, config)
      .then((res) => setAuthors(res?.data?.payload))
      .catch((err) => toast.error("Unable Get Book Author Stats!"));
  };

  const data = (sets) => {
    const data = {
      labels: sets.label,
      datasets: [
        {
          label: "# of Votes",
          data: sets.data,
          borderWidth: 0,
          backgroundColor: [
            "rgba(91, 207, 193,1)",
            "rgba(112, 159, 186, 1)",
            "rgba(238, 60, 60, 1)",
            "rgba(54, 147, 255, 1)",
            "rgba(255, 92, 0, 1)",
            "#E536A9",
            "#F0EA70",
            "#E8B6E3",
            "#CE8D2C",
            "#CD84BA",
            "#4B7724",
            "#B3612D",
            "#CC6752",
            "#B2C58E",
            "#9E1A72",
            "#303652",
            "#0BA394",
            "#38241A",
          ],
          borderWidth: 1,
        },
      ],
    };

    return data;
  };

  console.log("stats", stats);

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-money-coins text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Revenue</p>
                      <CardTitle tag="p">₹ {stats?.totalRevenue}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-calendar" /> Last day
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-vector text-danger" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Orders</p>
                      <CardTitle tag="p">{stats?.ordersCount}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="far fa-clock" /> In the last hour
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-globe text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Books</p>
                      <CardTitle tag="p">{stats?.bookCount}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update Now
                </div>
              </CardFooter> */}
            </Card>
          </Col>

          <Col lg="3" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-favourite-28 text-primary" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Active Users</p>
                      <CardTitle tag="p">{stats?.userCount}</CardTitle>
                      <p />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fas fa-sync-alt" /> Update now
                </div>
              </CardFooter> */}
            </Card>
          </Col>
        </Row>
        <Row>
          {categories && (
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Books by Category</CardTitle>
                </CardHeader>
                <CardBody>
                  {/* <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                /> */}
                  {categories && <PolarArea data={() => data(categories)} />}
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          )}

          {authors && (
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Books By Authors</CardTitle>
                </CardHeader>
                <CardBody>
                  {/* <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                /> */}
                  {authors && <PolarArea data={() => data(authors)} />}
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          )}
          {delivery && (
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Delivery</CardTitle>
                  <p className="card-category">24 Hours performance</p>
                </CardHeader>
                <CardBody>
                  {/* <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                /> */}
                  {delivery && <PolarArea data={() => data(delivery)} />}
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          )}

          {payment && (
            <Col md="6">
              <Card>
                <CardHeader>
                  <CardTitle tag="h5">Payment</CardTitle>
                  <p className="card-category">24 Hours performance</p>
                </CardHeader>
                <CardBody>
                  {/* <Line
                  data={dashboard24HoursPerformanceChart.data}
                  options={dashboard24HoursPerformanceChart.options}
                  width={400}
                  height={100}
                /> */}
                  {payment && <PolarArea data={() => data(payment)} />}
                </CardBody>
                {/* <CardFooter>
                  <hr />
                  <div className="stats">
                    <i className="fa fa-history" /> Updated 3 minutes ago
                  </div>
                </CardFooter> */}
              </Card>
            </Col>
          )}
        </Row>
        {/* <Row>
          <Col md="4">
            <Card>
              <CardHeader>
                <CardTitle tag="h5">Email Statistics</CardTitle>
                <p className="card-category">Last Campaign Performance</p>
              </CardHeader>
              <CardBody style={{ height: "266px" }}>
                <Pie
                  data={dashboardEmailStatisticsChart.data}
                  options={dashboardEmailStatisticsChart.options}
                />
              </CardBody>
              <CardFooter>
                <div className="legend">
                  <i className="fa fa-circle text-primary" /> Opened{" "}
                  <i className="fa fa-circle text-warning" /> Read{" "}
                  <i className="fa fa-circle text-danger" /> Deleted{" "}
                  <i className="fa fa-circle text-gray" /> Unopened
                </div>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar" /> Number of emails sent
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col md="8">
            <Card className="card-chart">
              <CardHeader>
                <CardTitle tag="h5">NASDAQ: AAPL</CardTitle>
                <p className="card-category">Line Chart with Points</p>
              </CardHeader>
              <CardBody>
                <Line
                  data={dashboardNASDAQChart.data}
                  options={dashboardNASDAQChart.options}
                  width={400}
                  height={100}
                />
              </CardBody>
              <CardFooter>
                <div className="chart-legend">
                  <i className="fa fa-circle text-info" /> Tesla Model S{" "}
                  <i className="fa fa-circle text-warning" /> BMW 5 Series
                </div>
                <hr />
                <div className="card-stats">
                  <i className="fa fa-check" /> Data information certified
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row> */}
      </div>
    </>
  );
}

export default Dashboard;
