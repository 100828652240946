import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SERVER_BASE_URL } from "../../config/config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import AppPagination from "helpers/appPagination";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function Youtube() {
  const history = useHistory();
  const [text, setText] = useState("");
  const [link, setLink] = React.useState("");
  const [links, setLinks] = React.useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const [add, setAdd] = useState("");
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(false);
  const [currentEditCategoryId, setCurrentEditCategoryId] = useState();
  const [currentEdit, setCurrentEdit] = useState({});
  const [fileNames, setFileNames] = useState([]);
  const [file, setFile] = useState([]);
  const [added, setAdded] = useState(false);
  const [title, setTitle] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = React.useState(false);

  const handleBulkAdd = async (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);

    const precheck = await axios.post(
      `${SERVER_BASE_URL}/v1/app/precheck-category-bulk`,
      formData,
      config
    );

    await axios.post(
      `${SERVER_BASE_URL}/v1/app/upload-category-bulk`,
      {
        data: precheck.data.payload,
      },
      config
    );

    history.push("/admin/add-categories");
  };

  const handleDrop = async (acceptedFiles) => {
    setFile(acceptedFiles);
    setFileNames(acceptedFiles[0].name);
    setAdded(true);
  };

  useEffect(() => {
    setLoading(true);
    getLinks();
  }, [edit, add, del, page]);

  const onEditClick = (link) => {
    setEdit(true);
    setCurrentEditCategoryId(link.id);
    setCurrentEdit({ ...link });
  };

  const onDeleteClick = (id) => {
    setDel(true);
    setCurrentDeleteId(id);
    handleDeleteClick(id);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const getLinks = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/link`, config)
      .then((res) => {
        setLinks(res.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  console.log(links);

  const handleEditChange = (e) => {
    let obj = currentEdit;
    obj = { ...obj, [e.target.name]: e.target.value };
    setCurrentEdit(obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(`${SERVER_BASE_URL}/v1/app/link`, { link, title }, config)
      .then((res) => setAdd(res))
      .catch((err) => console.log(err));
  };

  const handleEditCategory = async (e, id) => {
    e.preventDefault();

    await axios
      .patch(`${SERVER_BASE_URL}/v1/app/link/${id}`, { ...currentEdit }, config)
      .then((res) => {
        setEdit(false);
        setCurrentEdit({});
      })
      .catch((err) => console.log(err));
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    await axios
      .delete(`${SERVER_BASE_URL}/v1/app/link/${id}`, config)
      .catch((err) => console.log(err));
    setDel(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Add Youtube Link</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="12">
                      <FormGroup>
                        <label>Youtube Link</label>
                        <Input
                          placeholder="Link"
                          type="text"
                          name="link    "
                          onChange={(e) => setLink(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>{" "}
                  <Row>
                    <Col className="pr-1" md="12">
                      <FormGroup>
                        <label>Title</label>
                        <Input
                          placeholder="Title"
                          type="text"
                          name="title"
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-3">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Add Link
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          {edit && (
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Edit {currentEdit?.name}</CardTitle>
                </CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) =>
                      handleEditCategory(e, currentEditCategoryId)
                    }
                  >
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Youtube Link</label>
                          <Input
                            placeholder="Link"
                            type="text"
                            name="link"
                            defaultValue={currentEdit?.link}
                            onChange={(e) => handleEditChange(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>{" "}
                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Title</label>
                          <Input
                            placeholder="Title"
                            type="text"
                            name="title"
                            defaultValue={currentEdit?.title}
                            onChange={(e) => handleEditChange(e)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <div className="update ml-3">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Edit Link
                        </Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          )}

          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Youtube Links Database</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Link</th>
                      <th>Title</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {!loading
                      ? links?.results?.map((link) => (
                          <tr>
                            <td>
                              {" "}
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onEditClick(link)}
                              >
                                {link?.link ? link?.link : "Unnamed"}
                              </div>
                            </td>{" "}
                            <td>{link?.title}</td>
                            <td className="">
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onDeleteClick(link?.id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))
                      : "Loading..."}
                  </tbody>
                </Table>
              </CardBody>
              <AppPagination setPage={setPage} {...links} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Youtube;
