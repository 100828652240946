import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "./Login.css";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import logo from "../../assets/img/logo-sandhiya.png";
import axios from "axios";
import { SERVER_BASE_URL } from "config/config";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState("");

  const checkAuth = () => {
    const token = localStorage.getItem("token");

    if (token) {
      history.push("/admin/dashboard");
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    isUserLoggedIn();
  }, []);

  const isUserLoggedIn = () => {
    const user = localStorage.getItem("token");
    if (user) {
      history.push("/admin/dashboard");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/admin/dashboard");
    }
  }, [data]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const result = await axios.post(`${SERVER_BASE_URL}/v1/admin/login`, {
        email,
        password,
      });
      localStorage.setItem("token", result?.data?.tokens?.access?.token);
      setData(result?.data?.tokens?.access?.token);
      window.location.href = "/admin/dashboard";
    } catch (err) {
      toast.error("Incorrect Email or Password!");
    }
  };

  return (
    <div style={{ marginTop: "10%" }}>
      <Card>
        <div className="login">
          <div className="logo">
            <a href="/dashboard" className="simple-text logo-small">
              <div className="logo-img">
                <img width={"300px"} src={logo} alt="react-logo" />
              </div>
            </a>
          </div>
          <h5 style={{ textAlign: "center" }}>
            <b>Admin Login</b>
          </h5>

          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                type="email"
                name="email"
                id="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                name="password"
                id="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <Button>Login</Button>
          </Form>
        </div>
      </Card>
    </div>
  );
};

export default Login;
