/*!

=========================================================
* Paper Dashboard React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Licensed under MIT (https://github.com/creativetimofficial/paper-dashboard-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.js";
import Notifications from "views/Notifications.js";
import Icons from "views/Icons.js";
import Typography from "views/Typography.js";
import TableList from "views/Tables.js";
import Maps from "views/Map.js";
import UserPage from "views/User.js";
import UpgradeToPro from "views/Upgrade.js";
import AddBooks from "views/Books/AddBooks";
import AllBooks from "views/Books/AllBooks";
import AddCategory from "views/Category/AddCategory";
import AddAuthors from "views/Authors/AddAuthors";
import Settings from "views/Settings/Settings";
import Youtube from "views/Youtube/Youtube";
import Reports from "views/Reports/Reports";
import Users from "views/Users/Users";
import Banner from "views/Banner/Banner";
import Newsletter from "views/Newsletter/Newsletter";
import AddTranslators from "views/Translators/AddTranslators";
import AddCombos from "views/Combos/AddCombo";
import AllCombos from "views/Combos/AllCombos";
import StockList from "views/Reports/StockList";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
  },

  {
    path: "/users",
    name: "Users",
    icon: "nc-icon nc-bank",
    component: Users,
    layout: "/admin",
  },

  {
    path: "/books",
    name: "Books Database",
    icon: "nc-icon nc-bank",
    component: AllBooks,
    layout: "/admin",
  },

  {
    path: "/add-books",
    name: "Add Books",
    icon: "nc-icon nc-bank",
    component: AddBooks,
    layout: "/admin",
  },
  {
    path: "/combos",
    name: "Combos Database",
    icon: "nc-icon nc-bank",
    component: AllCombos,
    layout: "/admin",
  },
  {
    path: "/add-combo",
    name: "Add Combo",
    icon: "nc-icon nc-bank",
    component: AddCombos,
    layout: "/admin",
  },
  {
    path: "/add-categories",
    name: "Categories",
    icon: "nc-icon nc-bank",
    component: AddCategory,
    layout: "/admin",
  },

  {
    path: "/add-authors",
    name: "Authors",
    icon: "nc-icon nc-bank",
    component: AddAuthors,
    layout: "/admin",
  },

  {
    path: "/add-translators",
    name: "Translators",
    icon: "nc-icon nc-bank",
    component: AddTranslators,
    layout: "/admin",
  },
  {
    path: "/banner",
    name: "Banner",
    icon: "nc-icon nc-bank",
    component: Banner,
    layout: "/admin",
  },
  {
    path: "/youtubeLink",
    name: "Youtube Links",
    icon: "nc-icon nc-bank",
    component: Youtube,
    layout: "/admin",
  },

  {
    path: "/reports",
    name: "Reports",
    icon: "nc-icon nc-bank",
    component: Reports,
    layout: "/admin",
  },

  {
    path: "/stockList",
    name: "Low on Stock List",
    icon: "nc-icon nc-bank",
    component: StockList,
    layout: "/admin",
  },

  {
    path: "/newsletter",
    name: "Newsletter",
    icon: "nc-icon nc-bank",
    component: Newsletter,
    layout: "/admin",
  },

  {
    path: "/settings",
    name: "Settings",
    icon: "nc-icon nc-bank",
    component: Settings,
    layout: "/admin",
  },

  // {
  //   path: "/settings",
  //   name: "Settings",
  //   icon: "nc-icon nc-bank",
  //   component: Settings,
  //   layout: "/admin",
  // },
  // {
  //   path: "/report",
  //   name: "Report",
  //   icon: "nc-icon nc-bank",
  //   component: AddBooks,
  //   layout: "/admin",
  // },
  //   {
  //     path: "/icons",
  //     name: "Icons",
  //     icon: "nc-icon nc-diamond",
  //     component: Icons,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/maps",
  //     name: "Maps",
  //     icon: "nc-icon nc-pin-3",
  //     component: Maps,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/notifications",
  //     name: "Notifications",
  //     icon: "nc-icon nc-bell-55",
  //     component: Notifications,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/user-page",
  //     name: "User Profile",
  //     icon: "nc-icon nc-single-02",
  //     component: UserPage,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/tables",
  //     name: "Table List",
  //     icon: "nc-icon nc-tile-56",
  //     component: TableList,
  //     layout: "/admin",
  //   },
  //   {
  //     path: "/typography",
  //     name: "Typography",
  //     icon: "nc-icon nc-caps-small",
  //     component: Typography,
  //     layout: "/admin",
  //   },
  //   {
  //     pro: true,
  //     path: "/upgrade",
  //     name: "Upgrade to PRO",
  //     icon: "nc-icon nc-spaceship",
  //     component: UpgradeToPro,
  //     layout: "/admin",
  //   },
  //
];
export default routes;
