import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { Link } from "react-router-dom";
import { CLOUDINARY_CLOUD, SERVER_BASE_URL } from "../../config/config";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import CreatableSelect from "react-select/creatable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { toast } from "react-toastify";
import AppPagination from "helpers/appPagination";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function AllCombos() {
  const [data, setData] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [price, setPrice] = React.useState("");
  const [stock, setStock] = React.useState("");
  const [authors, setAuthors] = React.useState("");
  const [categoriesChosen, setCategoriesChosen] = React.useState("");
  const [authorChosen, setAuthorChosen] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [trending, setTrending] = React.useState("");
  const [featured, setFeatured] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [combo, setCombo] = React.useState("");
  const [books, setBooks] = React.useState("");
  const [translators, setTranslators] = React.useState([]);
  const [page, setPage] = useState(1);

  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(false);
  const [currentEditBookId, setCurrentEditBookId] = useState();
  const [currentEdit, setCurrentEdit] = useState("");
  const [search, setSearch] = useState("");
  const [image, setImage] = React.useState();

  useEffect(() => {}, [edit, del]);

  useEffect(() => {
    setLoading(true);
    getCombos({ search, page });
    getBooks();
  }, [edit, del, search, page]);

  const onEditClick = (book) => {
    setEdit(true);
    setCurrentEditBookId(book.id);
    setCurrentEdit(book);
  };

  const onDeleteClick = (id) => {
    setDel(true);
    setCurrentDeleteId(id);
    handleDeleteClick(id);
  };

  const uploadImage = (files) => {
    setImage(files[0]);
  };

  const handleInputChange = (e, type, name) => {
    let obj = data;
    if (type === "select") {
      obj = { ...obj, [name]: e.value };
    } else if (type === "multiSelect") {
      console.log(e);
      obj = { ...obj, [name]: e.map((item) => item.value) };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    setData(obj);
  };

  console.log(categoriesChosen);

  const handleEditSubmit = async (e, id) => {
    e.preventDefault();

    await axios
      .patch(`${SERVER_BASE_URL}/v1/admin/combo/${id}`, data, config)
      .then((res) => {
        toast.success("Success!");
        setEdit(false);
      })
      .catch((err) => toast.error("Unable Edit Books!"));
    setData({});

    console.log("daaataaa", data);
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    await axios
      .delete(`${SERVER_BASE_URL}/v1/admin/combo/${id}`, config)
      .then(() => toast.success("Deleted!"))
      .catch((err) => toast.error("Unable Delete Books!"));
    setDel(false);
  };

  const isTrendingOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const isFeaturedOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const getBooks = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/books?search=`, config)
      .then((res) => setBooks(res.data.payload))
      .catch((err) => toast.error("Unable Get Categories Data!"));
  };

  const getCombos = ({ search, page }) => {
    axios
      .get(
        `${SERVER_BASE_URL}/v1/app/combos?paginate=true&search=${search}&page=${page}`,
        config
      )
      .then((res) => {
        setCombo(res.data.payload);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setEdit(false);
    setData({});
  };

  console.log(books);

  const options = [
    { value: "Percentage", label: "Percentage" },
    { value: "Price", label: "Price" },
  ];

  return (
    <>
      <div className="content">
        <Row>
          {edit && (
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">Edit {currentEdit?.name}</CardTitle>
                </CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) => handleEditSubmit(e, currentEditBookId)}
                  >
                    <Row>
                      <Col className="pr-1" md="3">
                        <FormGroup>
                          <label>Title</label>
                          <Input
                            placeholder="Combo Title"
                            type="text"
                            name="name"
                            defaultValue={currentEdit?.name}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label>Offer Type</label>
                          <Select
                            options={options}
                            name="offerType"
                            defaultValue={{
                              label: currentEdit?.offerType,
                              value: currentEdit?.offerType,
                            }}
                            onChange={(e) =>
                              handleInputChange(e, "select", "offerType")
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col className="px-1" md="3">
                        <FormGroup>
                          <label>Offer</label>
                          <Input
                            placeholder="Price/Discount"
                            type="number"
                            defaultValue={currentEdit?.offer}
                            name="offer"
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="pr-1" md="6">
                        <FormGroup>
                          <label htmlFor="exampleInputEmail1">Books</label>
                          <Select
                            isMulti
                            options={books?.map((book) => ({
                              label: book?.title,
                              value: book?.id,
                            }))}
                            defaultValue={currentEdit?.books?.map((book) => ({
                              label: book?.title,
                              value: book?.id,
                            }))}
                            name="books"
                            onChange={(e) =>
                              handleInputChange(e, "multiSelect", "books")
                            }
                          />
                        </FormGroup>
                      </Col>{" "}
                    </Row>{" "}
                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Edit Book
                        </Button>
                      </div>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="button"
                          onClick={(e) => handleCancelEdit(e)}
                        >
                          Cancel Edit
                        </Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          )}

          <Col md="12">
            <Card style={{ minHeight: "700px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CardHeader>
                  <CardTitle tag="h4">Combos</CardTitle>
                </CardHeader>{" "}
                <input
                  style={{ width: "50%", marginRight: "1rem" }}
                  id="search"
                  name="search"
                  type="text"
                  className="form-control"
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                  required
                />
              </div>
              <CardBody>
                <Table responsive style={{ width: "1800px" }}>
                  <thead className="text-primary">
                    <tr>
                      <th width="600">Combo Title</th>
                      <th>Offer Type</th>
                      <th>Offer</th>
                      <th>Books</th>
                    </tr>
                  </thead>
                  <tbody style={{ minHeight: "550px" }}>
                    {!loading
                      ? combo &&
                        combo?.map((book) => (
                          <tr>
                            <td>
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onEditClick(book)}
                              >
                                {book?.name ? book?.name : "Unnamed"}
                              </div>
                            </td>
                            <td>{book?.offerType ? book?.offerType : "N/A"}</td>
                            <td>{book?.offer ? book?.offer : "N/A"}</td>
                            <td>
                              {book?.books
                                ? book?.books?.map((d) => <tr>{d?.title}</tr>)
                                : "N/A"}
                            </td>
                            <td className="">
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onDeleteClick(book?.id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))
                      : "Loading..."}
                  </tbody>
                </Table>
              </CardBody>
              <AppPagination setPage={setPage} {...books} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AllCombos;
