import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Select from "react-select";
import "./AddBooks.css";
import { CLOUDINARY_CLOUD, SERVER_BASE_URL } from "../../config/config";
import Dropzone, { useDropzone } from "react-dropzone";
import CreatableSelect from "react-select/creatable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function AddBooks() {
  const history = useHistory();
  const [data, setData] = React.useState({});
  const [authors, setAuthors] = React.useState([]);
  const [translators, setTranslators] = React.useState([]);
  const [categoriesChosen, setCategoriesChosen] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [image, setImage] = React.useState();
  const [URL, setURL] = React.useState();
  const [fileNames, setFileNames] = useState([]);

  const [file, setFile] = useState([]);
  const [added, setAdded] = useState(false);

  const handleBulkAdd = async (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);

    const precheck = await axios.post(
      `${SERVER_BASE_URL}/v1/app/precheck-product-bulk`,
      formData,
      config
    );

    await axios.post(`${SERVER_BASE_URL}/v1/app/upload-product-bulk`, {
      data: precheck.data.payload,
      config,
    });

    history.push("/admin/books");
  };

  const handleDrop = async (acceptedFiles) => {
    setFile(acceptedFiles);
    setFileNames(acceptedFiles[0].name);
    setAdded(true);
  };

  useEffect(() => {
    getCategories();
    getAuthors();
    getTranslators();
  }, []);

  const getCategories = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/categories?search=`, config)
      .then((res) => setCategories(res.data.payload))
      .catch((err) => toast.error("Unable Get Categories Data!"));
  };

  const getAuthors = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/authors?search=`, config)
      .then((res) => setAuthors(res.data.payload))
      .catch((err) => toast.error("Unable Get Authors Data!"));
  };

  const getTranslators = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/translators?search=`, config)
      .then((res) => setTranslators(res.data.payload))
      .catch((err) => toast.error("Unable Get Authors Data!"));
  };

  console.log(categories);

  const handleInputChange = (e, type, name) => {
    let obj = data;
    if (type === "select") {
      obj = { ...obj, [name]: e.value };
    } else if (type === "multiSelect") {
      console.log(e);
      obj = { ...obj, [name]: e.map((item) => item.value) };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    setData(obj);
  };

  console.log(categoriesChosen);

  const uploadImage = (files) => {
    setImage(files[0]);
  };

  console.log(image);

  const handleSubmit = async (e) => {
    e.preventDefault(e);

    console.log(data);
    const fd = new FormData();
    fd.append("file", image);

    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        data[key].forEach((d) => fd.append(key, d));
        return;
      }
      fd.append(key, data[key]);
    });
    // Object.entries(fd).map((f) => console.log(`data, ${f[0]} : ${f[1]}`));

    // const URL = await axios
    //   .post(
    //     `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD}/image/upload`,
    //     fd
    //   )
    //   .catch((err) => toast.error("Unable to Upload Image!"));

    // if (URL) {
    //   await axios
    //     .post(
    //       `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD}/image/upload`,
    //       fd
    //     )
    //     .then(() => {
    //       history.push("/admin/books");
    //     })
    //     .catch((err) => toast.error("Unable to Upload Image!"));
    // } else {
    //   await axios
    //     .post(`${SERVER_BASE_URL}/v1/app/add-book`, data, config)
    //     .then(() => {
    //       history.push("/admin/books");
    //     })
    //     .catch((err) => toast.error("Unable to Add Book!"));
    // }
    console.log("data", data, image);
    await axios
      .post(`${SERVER_BASE_URL}/v1/app/add-book`, fd, config)
      .then(() => {
        history.push("/admin/books");
      })
      .catch((err) => toast.error("Unable to Add Book!"));
    setImage();

    setData({});
  };

  console.log("urlllllllllllll", categories, authors);

  // const categoryOptions = categories?.payload?.results?.map((category) => ({
  //   value: category.id,
  //   label: category.name + " " + "(" + category.id + ")",
  // }));

  // const authorOptions = authors?.results?.map((author) => ({
  //   value: author.id,
  //   label: author.name + " " + "(" + author.id + ")",
  // }));

  const isTrendingOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const isFeaturedOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              {/* <CardHeader>
                <CardTitle tag="h5">Add Books</CardTitle>
              </CardHeader> */}
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Title</label>
                        <Input
                          placeholder="Book Title"
                          type="text"
                          name="title"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <label>Price</label>
                        <Input
                          placeholder="Book Price"
                          type="number"
                          name="price"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <label>Discount</label>
                        <Input
                          placeholder="Discount Price"
                          type="number"
                          name="discount"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="3">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Stock</label>
                        <Input
                          placeholder="No of Stock"
                          type="number"
                          name="stock"
                          onChange={handleInputChange}
                          min={1}
                          max={100}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Brand</label>
                        <Input
                          type="text"
                          name="brand"
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label>Author</label>
                        <Select
                          options={[
                            { label: "N/A", value: null },
                            ...authors?.map((author) => ({
                              label: author.name,
                              value: author.id,
                            })),
                          ]}
                          name="author"
                          onChange={(e) =>
                            handleInputChange(e, "select", "author")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="" md="6">
                      <FormGroup>
                        <label>Translator</label>
                        <Select
                          name="translator"
                          options={[
                            { label: "N/A", value: null },
                            ...translators?.map((translator) => ({
                              label: translator.name,
                              value: translator.id,
                            })),
                          ]}
                          onChange={(e) =>
                            handleInputChange(e, "select", "translator")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Category</label>
                        <Select
                          isMulti
                          options={categories?.map((category) => ({
                            label: category.name,
                            value: category.id,
                          }))}
                          name="category"
                          onChange={(e) =>
                            handleInputChange(e, "multiSelect", "category")
                          }
                        />
                      </FormGroup>
                    </Col>{" "}
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Tags</label>
                        <Input
                          type="text"
                          name="tags"
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">
                          Published Year
                        </label>
                        <Input
                          type="number"
                          name="publishedYear"
                          onChange={(e) => handleInputChange(e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-3" md="6">
                      <FormGroup>
                        <label>Is New Arrival?</label>
                        <Select
                          options={isTrendingOptions}
                          name="isNewArrival"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isNewArrival")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="6">
                      <FormGroup>
                        <label>Is Best Seller?</label>
                        <Select
                          options={isFeaturedOptions}
                          name="isBestSeller"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isBestSeller")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pl-3" md="4">
                      <FormGroup>
                        <label>Is Old Author Choice?</label>
                        <Select
                          options={isTrendingOptions}
                          name="isTrending"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isOldAuthorChoice")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Is Trending?</label>
                        <Select
                          options={isTrendingOptions}
                          name="isTrending"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isTrending")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pl-1" md="4">
                      <FormGroup>
                        <label>Is Featured?</label>
                        <Select
                          options={isFeaturedOptions}
                          name="isFeatured"
                          onChange={(e) =>
                            handleInputChange(e, "select", "isFeatured")
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="4">
                      {" "}
                      <FormGroup>
                        <label for="file">Choose a file</label>
                        <Input
                          type="file"
                          id="file"
                          class="inputfile"
                          name="image"
                          onChange={(e) => uploadImage(e.target.files)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Description</label>
                        <Input
                          type="textarea"
                          name="description"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Add Book
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>{" "}
        <Row>
          <Col md="12">
            {" "}
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Bulk Add Books</CardTitle>
              </CardHeader>
              <Dropzone
                onDrop={handleDrop}
                accept="image/*"
                minSize={1024}
                maxSize={3072000}
                disabled={added === true ? true : false}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? "accept"
                    : isDragReject
                    ? "reject"
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <span>{isDragActive ? "📂" : "📁"}</span>
                      <p>
                        {added
                          ? fileNames
                          : "Drag'n'drop images, or click to select files"}
                      </p>
                    </div>
                  );
                }}
              </Dropzone>{" "}
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    onClick={() => handleBulkAdd(file)}
                    className="btn-round"
                    color="primary"
                    type="submit"
                  >
                    Bulk Add
                  </Button>
                </div>
              </Row>
            </Card>{" "}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddBooks;
