import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import Select from "react-select";
import { CLOUDINARY_CLOUD, SERVER_BASE_URL } from "../../config/config";
import Dropzone, { useDropzone } from "react-dropzone";
import CreatableSelect from "react-select/creatable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
import { toast } from "react-toastify";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function AddCombos() {
  const history = useHistory();
  const [data, setData] = React.useState({});
  const [authors, setAuthors] = React.useState([]);
  const [translators, setTranslators] = React.useState([]);
  const [categoriesChosen, setCategoriesChosen] = React.useState("");
  const [books, setBooks] = React.useState([]);
  const [image, setImage] = React.useState();
  const [URL, setURL] = React.useState();
  const [fileNames, setFileNames] = useState([]);

  const [file, setFile] = useState([]);
  const [added, setAdded] = useState(false);

  useEffect(() => {
    getBooks();
  }, []);

  const getBooks = () => {
    axios
      .get(`${SERVER_BASE_URL}/v1/app/books?search=`, config)
      .then((res) => setBooks(res.data.payload))
      .catch((err) => toast.error("Unable Get Categories Data!"));
  };

  const handleInputChange = (e, type, name) => {
    let obj = data;
    if (type === "select") {
      obj = { ...obj, [name]: e.value };
    } else if (type === "multiSelect") {
      console.log(e);
      obj = { ...obj, [name]: e.map((item) => item.value) };
    } else {
      obj = { ...obj, [e.target.name]: e.target.value };
    }

    setData(obj);
  };

  console.log(categoriesChosen);

  const uploadImage = (files) => {
    setImage(files[0]);
  };

  console.log(image);

  const handleSubmit = async (e) => {
    e.preventDefault(e);

    console.log("daataa", data);

    await axios
      .post(`${SERVER_BASE_URL}/v1/admin/combo`, data, config)
      .then(() => {
        history.push("/admin/books");
      })
      .catch((err) => toast.error("Unable to Add Combo!"));
  };

  const options = [
    { value: "Percentage", label: "Percentage" },
    { value: "Price", label: "Price" },
  ];

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              {/* <CardHeader>
                <CardTitle tag="h5">Add Books</CardTitle>
              </CardHeader> */}
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Title</label>
                        <Input
                          placeholder="Combo Title"
                          type="text"
                          name="name"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <label>Offer Type</label>
                        <Select
                          options={options}
                          name="offerType"
                          onChange={(e) =>
                            handleInputChange(e, "select", "offerType")
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-1" md="3">
                      <FormGroup>
                        <label>Offer</label>
                        <Input
                          placeholder="Price/Discount"
                          type="number"
                          name="offer"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="pr-1" md="6">
                      <FormGroup>
                        <label htmlFor="exampleInputEmail1">Books</label>
                        <Select
                          isMulti
                          options={books?.map((book) => ({
                            label: book.title,
                            value: book.id,
                          }))}
                          name="books"
                          onChange={(e) =>
                            handleInputChange(e, "multiSelect", "books")
                          }
                        />
                      </FormGroup>
                    </Col>{" "}
                  </Row>

                  <Row>
                    <div className="update ml-auto mr-auto">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Add Combo
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>{" "}
      </div>
    </>
  );
}

export default AddCombos;
