import React from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./NewsletterToolbar";
import "react-quill/dist/quill.snow.css";
import { Button } from "reactstrap";
import { SERVER_BASE_URL } from "config/config";
import axios from "axios";
import { toast } from "react-toastify";

export const Newsletter = () => {
  const token = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const [content, setContent] = React.useState(null);

  const handleChange = (value) => {
    console.log(value);
    setContent(value);
  };

  const handleSubmit = async () => {
    await axios.post(
      `${SERVER_BASE_URL}/v1/admin/send-newsletter`,
      {
        content,
      },
      config
    );

    toast.success("Newsletter Sent to all Recipients!");

    setContent("");
  };

  return (
    <>
      <div className="text-editor">
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={content}
          onChange={handleChange}
          placeholder={"Write something..."}
          modules={modules}
          formats={formats}
        />
      </div>
      <div className="update ml-auto mr-auto">
        <Button
          className="btn-round"
          color="primary"
          type="button"
          onClick={handleSubmit}
        >
          Send Newsletter
        </Button>
      </div>
    </>
  );
};

export default Newsletter;
