import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";
import { useHistory } from "react-router";
import "./AddBooks.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SERVER_BASE_URL } from "../../config/config";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Dropzone, { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import AppPagination from "helpers/appPagination";

const token = localStorage.getItem("token");
const config = {
  headers: { Authorization: `Bearer ${token}` },
};

function AddCategory() {
  const history = useHistory();
  const [text, setText] = useState("");
  const [data, setData] = React.useState({});
  const [categories, setCategories] = React.useState([]);
  const [isCopied, setIsCopied] = useState(false);

  const [add, setAdd] = useState("");
  const [edit, setEdit] = useState(false);
  const [del, setDel] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(false);
  const [currentEditCategory, setCurrentEditCategory] = useState();
  const [fileNames, setFileNames] = useState([]);
  const [file, setFile] = useState([]);
  const [added, setAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const handleBulkAdd = async (file) => {
    let formData = new FormData();
    formData.append("file", file[0]);

    const precheck = await axios.post(
      `${SERVER_BASE_URL}/v1/app/precheck-category-bulk`,
      formData
    );

    await axios.post(
      `${SERVER_BASE_URL}/v1/app/upload-category-bulk`,
      {
        data: precheck.data.payload,
      },
      config
    );

    history.push("/admin/add-categories");
  };

  const handleDrop = async (acceptedFiles) => {
    setFile(acceptedFiles);
    setFileNames(acceptedFiles[0].name);
    setAdded(true);
  };

  useEffect(() => {
    setLoading(true);
    getCategories();
  }, [edit, add, del, search, page]);

  const onEditClick = (id, name, en) => {
    setEdit(true);
    setCurrentEditCategory({ id, name, en });
  };

  const onDeleteClick = (id) => {
    setDel(true);
    setCurrentDeleteId(id);
    handleDeleteClick(id);
  };

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const getCategories = () => {
    axios
      .get(
        `${SERVER_BASE_URL}/v1/app/categories?paginate=true&page=${page}&search=${search}`,
        config
      )
      .then((res) => {
        setCategories(res.data.payload);
        setLoading(false);
      })
      .catch((err) => {
        toast.error("Unable to Search!");
        setLoading(false);
      });
  };

  console.log("hhh", categories);

  const handleInputChange = (e) => {
    let obj = data;
    obj = { ...obj, [e.target.name]: e.target.value };
    setData(obj);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await axios
      .post(`${SERVER_BASE_URL}/v1/app/add-category`, { ...data }, config)
      .then((res) => {
        setAdd(res);
        setData({});
        toast.success("Success!");
      })
      .catch((err) => {
        toast.error("Unable to Add Category!");
        setData({});
      });
  };

  const handleCancelEdit = (e) => {
    e.preventDefault();
    setEdit(false);
    setData({});
  };

  const handleEditCategory = async (e, id) => {
    e.preventDefault();

    await axios
      .patch(`${SERVER_BASE_URL}/v1/app/category/${id}`, { ...data }, config)
      .then((res) => {
        setEdit(false);
        toast.success("Success!");
      })
      .catch((err) => toast.error("Unable to Edit Category!"));
  };

  const handleDeleteClick = async (id) => {
    console.log(id);
    await axios
      .post(`${SERVER_BASE_URL}/v1/app/category/${id}`, config)
      .then(() => {
        toast.success("Deleted!");
      })
      .catch((err) => toast.error("Unable to Delete Category!"));
    setDel(false);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Add Category</CardTitle>
              </CardHeader>
              <CardBody>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col className="pr-1" md="5">
                      <FormGroup>
                        <label>Category Name</label>
                        <Input
                          placeholder="Category Name"
                          type="text"
                          name="name"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="pr-1" md="3">
                      <FormGroup>
                        <label>Name in English</label>
                        <Input
                          placeholder="Name in English"
                          type="text"
                          name="en"
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <div className="update ml-3">
                      <Button
                        className="btn-round"
                        color="primary"
                        type="submit"
                      >
                        Add Category
                      </Button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
          {edit && (
            <Col md="12">
              <Card className="card-user">
                <CardHeader>
                  <CardTitle tag="h5">
                    Edit {currentEditCategory?.name}
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <form
                    onSubmit={(e) =>
                      handleEditCategory(e, currentEditCategory?.id)
                    }
                  >
                    <Row>
                      <Col className="pr-1" md="5">
                        <FormGroup>
                          <label>Category Name</label>
                          <Input
                            placeholder="Category Name"
                            type="text"
                            name="name"
                            defaultValue={currentEditCategory?.name}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                      <Col className="pr-1" md="3">
                        <FormGroup>
                          <label>Name in English</label>
                          <Input
                            placeholder="Name in English"
                            type="text"
                            name="en"
                            defaultValue={currentEditCategory?.en}
                            onChange={handleInputChange}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <div className="update ml-3">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          Edit Category
                        </Button>
                      </div>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="button"
                          onClick={(e) => handleCancelEdit(e)}
                        >
                          Cancel Edit
                        </Button>
                      </div>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          )}
          <Col md="12">
            {" "}
            <Card className="card-user">
              <CardHeader>
                <CardTitle tag="h5">Bulk Add Categories</CardTitle>
              </CardHeader>
              <Dropzone
                onDrop={handleDrop}
                accept="image/*"
                minSize={1024}
                maxSize={3072000}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                }) => {
                  const additionalClass = isDragAccept
                    ? "accept"
                    : isDragReject
                    ? "reject"
                    : "";

                  return (
                    <div
                      {...getRootProps({
                        className: `dropzone ${additionalClass}`,
                      })}
                    >
                      <input {...getInputProps()} />
                      <span>{isDragActive ? "📂" : "📁"}</span>
                      {added
                        ? fileNames
                        : "Drag'n'drop images, or click to select files"}
                    </div>
                  );
                }}
              </Dropzone>{" "}
              <Row>
                <div className="update ml-auto mr-auto">
                  <Button
                    onClick={() => handleBulkAdd(file)}
                    className="btn-round"
                    color="primary"
                    type="submit"
                  >
                    Bulk Add
                  </Button>
                </div>
              </Row>
            </Card>
          </Col>
          <Col md="12">
            <Card style={{ minHeight: "700px" }}>
              <CardHeader>
                <CardTitle tag="h4">Category Database</CardTitle>
              </CardHeader>{" "}
              <input
                id="search"
                name="search"
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={(e) => {
                  setSearch(e.target.value);
                  setPage(1);
                }}
                required
              />
              <CardBody>
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th>Name</th>
                      <th>ID</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody style={{ minHeight: "550px" }}>
                    {console.log(categories)}
                    {!loading
                      ? categories?.results?.map((category) => (
                          <tr>
                            <td>
                              {" "}
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() =>
                                  onEditClick(
                                    category?.id,
                                    category?.name,
                                    category?.en
                                  )
                                }
                              >
                                {category?.name ? category?.name : "Unnamed"}
                              </div>
                            </td>{" "}
                            <CopyToClipboard
                              text={category?.id}
                              onCopy={onCopyText}
                            >
                              <td
                                onChange={(event) =>
                                  setText(event.target.value)
                                }
                              >
                                <div
                                  style={{
                                    cursor: "pointer",
                                    color: "#51cbce",
                                  }}
                                >
                                  {category?.id}
                                </div>
                              </td>
                            </CopyToClipboard>
                            <td className="">
                              <div
                                style={{ cursor: "pointer", color: "#51cbce" }}
                                onClick={() => onDeleteClick(category?.id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        ))
                      : "Loading..."}
                  </tbody>
                </Table>
              </CardBody>
              <AppPagination setPage={setPage} {...categories} />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddCategory;
